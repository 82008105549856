import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import lazy from "./directives/lazy";

const app = createApp(App);
app.use(ElementPlus, { size: "small", zIndex: 3000 });
app.use(router);
app.use(store);

app.directive("lazy", lazy);

app.mount("#app");
