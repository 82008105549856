const api = {
  // host: "http://localhost:8085",
  host: "http://111.229.10.74:8085", //线上服务器域名
  getTest: "/getTest",
  host_dong: "http://47.93.25.207:8085",
  countUniversal: "/countUniversal",
  D_select: "/selectUniversal",
  UniversalUserData: "/UniversalUserData",
  manageUser: "/manageUser",
  getUserDataById: "/getUserDataById",
  downLoad: "/downLoad",
  getAllOrderByName: "/getAllOrderByName",
  upload3dNodel: "/upload3dNodel",
  universal: "/universal",
  selectUniversal: "/selectUniversal",
  threedModel: "/3dModel",
  get3dList: "/get3dList",
  sysUserData: "/sysUserData",
  searchSys: "/searchSys",
  addPhotoToSearch: "/addPhotoToSearch",
  allUserInfo: "/allUserInfo",
  allPhotoInfo: "/allPhotoInfo",
  getAllOrder: "/getAllOrder",
  eachOrder: "/eachOrder",
  searchById: "/searchById",
  eachUserPhotos: "/eachUserPhotos",
  kilalaLogin: "/kilalaLogin",
  changeUserLevel: "/changeUserLevel",
  deletaPhotoById: "/deletaPhotoById",
  changeUserName: "/changeUserName",
  searchChinese: "/searchChinese",
  searchLatin: "/searchLatin",
  searByFamily: "/searByFamily",
  searByOrder: "/searByOrder",
  addPhotoInfo: "/addPhotoInfo",
  uploadFile: "/uploadFile", //上传照片
  register: "/register", //注册
  test: "/postpost",
  testGet: "/testGet",
  changeUserHead: "/changeUserHead", //修该用户头像
  updatePhoto: "/updatePhoto", //修改照片信息
  isUsername: "/isUsername", //判断用户名是否存在
  changephosuser: "/changephosuser", //修改照片信息
  userInfo: "/userInfo", //根据用户名查询用户闽西
  comment: "/comment", //向数据库插入一条评论
  getMessages: "/getMessages", //根据图片id获取评论信息
  deleteMessage: "/deleteMessage", //根据评论id删除该评论
  replyMessage: "/replyMessage", //回复留言功能
  replyMessage: "/replyMessage", //回复留言功能
  addProtectedInsect: "/addProtectedInsect", //上传野保昆虫接口
  getProtectedInsect: "/getProtectedInsect", //查询野保昆虫接口
  deleteOneProtectedInsect: "/deleteOneProtectedInsect", //删除一条野保信息
  condition01y: "/condition01y",
  condition01y02y: "/condition01y02y",
  condition01y02y03y: "/condition01y02y03y",
  con01n: "/con01n",
  con01n02y: "/con01n02y",
  keyData: "/keyData", //检索表全部数据
  keyAcridoidea: "/keyAcridoidea",
  getKeyDataByCondition_01: "/getKeyDataByCondition_01",
  getKeyDataByCondition_02: "/getKeyDataByCondition_02",
  getKeyDataByCondition_03: "/getKeyDataByCondition_03",
  getKeyDataByCondition_04: "/getKeyDataByCondition_04",
  getKeyTextAcridoidea: "/getKeyTextAcridoidea",
  getImportinsectInfo: "/getImportinsectInfo",
  getImportinsectInfoById: "/getImportinsectInfoById",
  updatePricerinportinsect: "/updatePricerinportinsect",
  addImportInsect: "/addImportInsect",
  addPriceRecordInsect: "/addPriceRecordInsect",
  getPriceRecordInsect: "/getPriceRecordInsect",
  updatePriceRecordInsect: "/updatePriceRecordInsect",
  getPriceRecordInsectById: "/getPriceRecordInsectById",
  getPriceZhongkun: "/getPriceZhongkun",
  getSpeciesZhongkun: "/getSpeciesZhongkun",
  updateSpeciesZhongkun: "/updateSpeciesZhongkun",
  getPriceZhongkunById: "/getPriceZhongkunById",
  addOrder: "/addOrder",
  getNum: "/getNum",
  getOrderByNum: "/getOrderByNum",
  deletaOrderByNum: "/deletaOrderByNum",
  getPriceZhongkunByNote: "/getPriceZhongkunByNote",
  getPriceRecordZhongkun: "/getPriceRecordZhongkun",
};

export default api;
