import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  namespaced: true,
  state: {
    sum: 10,
    userName: "",
    userId: "",
    userPhotoName: "",
    level: "",
    peimissions: "",
    goods_family: "",
    token: "",
  },
  mutations: {
    updataGoods(state, para01) {
      state.goods_family = para01;
    },
    addUserId(state, id) {
      state.userId = id;
    },
    addUserName(state, name) {
      state.userName = name;
    },
    clearData(state) {
      state.userName = "";
      state.userId = "";
    },
    addUserPhoto(state, imgName) {
      state.userPhotoName = imgName;
    },
    addUserLevel(state, userLevel) {
      state.level = userLevel;
    },
    addUserPeimissions(state, peimissions) {
      state.peimissions = peimissions;
    },
    addUserToken(state, token) {
      state.token = token;
    },
  },
  action: {},
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      key: "store",
      render(state) {
        return { ...state };
      },
    }),
  ],
});
